import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import '../assets/sass/main.scss';
import Footer from './Footer';

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPreloaded: true,
    };
  }

  componentDidMount() {
    this.timeoutId = setTimeout(() => {
      this.setState({ isPreloaded: false });
    }, 100);
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  render() {
    const { children } = this.props;
    const { isPreloaded } = this.state;

    return (
      <>
        {/* Meta tags example */}
        <Helmet
          title={'Range Drills'}
          meta={[
            { name: 'description', content: 'Shoot Better, Hit Your Mark!' },
            { name: 'keywords', content: 'site, web' },
          ]}
        >
          <html lang="en" />
        </Helmet>
        <div
          className={isPreloaded ? 'main-body is-preload' : 'main-body'}
        >
          <div id="page-wrapper">
            {/* <SideBar fullMenu={fullMenu} /> */}
            {children}
            <Footer />
          </div>
        </div>
      </>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  fullMenu: PropTypes.bool,
};

export default Layout;
