import React from "react";
import 'react-multi-carousel/lib/styles.css';
import PicturesSlider from './PicturesSlider'


export default function Features() {
  return (
    <section id="four" className="wrapper alt style1">
      <div className="inner">
        <h2 className="major">Showcasing RangeDrills: Your Path to Precision and Progress</h2>
        <p>
        Discover how Range Drills empowers you to enhance your shooting skills and track your improvement with these app screenshots. Explore its features and capabilities at a glance.
        </p>
        <PicturesSlider/>
      </div>
    </section>
  );
}
