import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import screenshot1 from "../assets/images/Screenshot1.png";
import screenshot2 from "../assets/images/Screenshot2.png";
import screenshot3 from "../assets/images/Screenshot3.png";
import screenshot4 from "../assets/images/Screenshot4.png";
import screenshot5 from "../assets/images/Screenshot5.png";
import screenshot6 from "../assets/images/Screenshot6.png";
import screenshot7 from "../assets/images/Screenshot7.png";
import screenshot8 from "../assets/images/Screenshot8.png";


export default class PicturesSlider extends Component {
  render() {
    const imageStyle = {
        width: "100%",
        maxWidth: "30vh",
        height: "auto",
        margin: "0 auto",
      };
      
    return (
        <>
        <style>
          {`
            .thumbs.animated {
              display: flex;
              justify-content: center;
            }
          `}
        </style>
      <Carousel autoPlay infiniteLoop showThumbs={false}>
        <div>
          <img src={screenshot1} alt="Slide 1" style={imageStyle} />
        </div>
        <div>
          <img src={screenshot2} alt="Slide 2" style={imageStyle} />
        </div>
        <div>
          <img src={screenshot3} alt="Slide 3" style={imageStyle} />
        </div>
        <div>
          <img src={screenshot4} alt="Slide 4" style={imageStyle} />
        </div>
        <div>
          <img src={screenshot5} alt="Slide 5" style={imageStyle} />
        </div>
        <div>
          <img src={screenshot6} alt="Slide 6" style={imageStyle} />
        </div>
        <div>
          <img src={screenshot7} alt="Slide 7" style={imageStyle} />
        </div>
        <div>
          <img src={screenshot8} alt="Slide 8" style={imageStyle} />
        </div>
      </Carousel>
      </>
    );
  }
}