import React from 'react';

import Layout from '../components/Layout';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import logo from '../assets/images/logoOnly.jpg';
import pic1 from '../assets/images/section1.jpg';
import pic2 from '../assets/images/section4.jpg';
import Features from '../components/Features'

const imageStyle = {
  width: "10vh", // Adjusts the width of each image
  // maxWidth: "200px", // Prevents images from getting too large
  height: "auto", // Maintains aspect ratio
  borderRadius: "30px", // Adds rounded corners
};

const overlayStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  width: "10vh",
  height: "90%",
  backgroundColor: "rgba(0, 0, 0, 0.25)", // Semi-transparent black overlay
  borderRadius: "30px", // Matches the border radius of the image
  pointerEvents: "none", // Ensures the overlay doesn't block clicks on the image
};

const IndexPage = () => (
  <Layout>
    <section id="banner">
      <div className="inner">
        <div className="logo">
          {/* <span className="icon fa-bullseye"></span> */}
          <img src={logo} alt="" style={imageStyle} />
          <div style={overlayStyle}></div>
        </div>
        <h2>Range Drills</h2>
        <h3>Shoot Better, Hit Your Mark!</h3>
      </div>
    </section>

    <section id="wrapper">
      <section id="one" className="wrapper spotlight style1">
        <div className="inner">
          <a href="/#" className="image">
            <img src={pic1} alt="" />
          </a>
          <div className="content">
            <h2 className="major">About Range Drills</h2>
            <p>
              <b>Range Drills</b> is a powerful app designed to help shooters improve their skills, precision, and safety. 
              Whether you’re a beginner or an experienced marksman, the app provides simple, effective tools to refine your technique, develop safe firearm handling habits, track your progress, and achieve your shooting goals.
            <b>Train Smarter. Shoot Better. Hit Your Mark.</b>
            </p>
            {/* <a href="/#two" className="special">
              Learn more
            </a> */}
          </div>
        </div>
      </section>

      <section id="two" className="wrapper alt spotlight style2">
        <div className="inner">
          <a href="/#" className="image">
            <img src={pic2} alt="" />
          </a>
          <div className="content">
          <h2 className="major">How Can Range Drills Help?</h2>
          <div>
            <strong>Range Drills</strong> is designed to guide you through structured programs and drills, helping you learn and improve your shooting skills while emphasizing safety and confidence. With preloaded sessions tailored for all levels, the app provides:
            <ul>
              <li><strong>Guided Training Programs:</strong> Follow step-by-step drills designed to build precision, safety, and confidence.</li>
              <li><strong>Skill Development:</strong> Practice drills to enhance core shooting fundamentals, advanced techniques, and safe firearm handling.</li>
              <li><strong>Progress Tracking:</strong> Monitor your journey and see how your skills and safe practices improve over time.</li>
            </ul>
            Let <strong>Range Drills</strong> help you grow as a shooter with expertly crafted programs to refine your technique, prioritize safety, and achieve your goals.
          </div>
            {/* <a href="/#" className="special">
              Learn more
            </a> */}
          </div>
        </div>
      </section>
      <Features/>
    </section>
  </Layout>
);

export default IndexPage;
