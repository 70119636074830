import React from 'react';

export default function Footer() {
  return (
    <section id="footer">
      <div className="inner">
        <h2 className="major">Get in Touch</h2>
        <p style={{ fontSize: "1em", lineHeight: "1.6" }}>
          We’re excited to announce that we’re starting our beta testing! If you’re interested in
          joining the beta group, providing feedback, or asking questions, we’d love to hear from you.
          Reach out to <a href="mailto:rangedrillsio@gmail.com" style={{textDecoration: "none" }}>rangedrillsio@gmail.com</a>.
        </p>
        <p>
          Click the button below to send us an email and be part of the Range Drills journey!
        </p>
        <button
          onClick={() => window.location.href = "mailto:rangedrillsio@gmail.com?subject=Beta Testing Inquiry&body=Hi, I’m interested in joining the beta testing group..."} 
          className="actions"
        >
          Email Us to Join the Beta
        </button>
          <ul className="copyright" style={{ marginTop: "20px", display: 'flex',justifyContent: 'center' }}>
            <li>&copy; Range Drills. All rights reserved.</li>
            <li>Developed by iDoDev LLC</li>
            <li>
              Design: <a href="http://html5up.net">HTML5 UP</a>
            </li>
        </ul>
      </div>
    </section>
  );
}
